.categories-grid-responsive {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.ordered-item {
  background-color: transparent;
  padding: 10px;
  border-radius: 8px;
  border: none;
  box-shadow: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.category-hover:hover {
  transform: scale(1.05);
  box-shadow: none;
  opacity: 0.8;
}

.item-details {
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: left;
  flex-grow: 1;
}

.item-details p {
  margin: 0;
}

.item-image {
  width: 110px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
}

@media (min-width: 1024px) {
  .categories-grid-responsive {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .categories-grid-responsive {
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
  }
}


