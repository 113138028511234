.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
  justify-content: center; /* Centers single items */
}

.product-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  padding: 1rem;
  max-width: 300px; /* Limits the width of the product card */
  margin: 0 auto; /* Centers the card within its grid column */
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 150px;
}

.product-name {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.product-price {
  font-size: 1rem;
  color: #555;
}

.noUserProducts {
  text-align: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #777;
  padding: 2rem;
}
