.orders-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.heading {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

.loading,
.no-orders {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
}

.orders-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}

.order-card {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.order-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.order-info p {
  font-size: 0.875rem;
  color: #555;
  margin: 6px 0;
}

.ordered-items {
  margin-top: 24px;
}

.items-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 12px;
}

.items-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

.ordered-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-weight: 600;
  font-size: 0.875rem;
}

.item-quantity,
.item-price {
  font-size: 0.75rem;
  color: #666;
}

/* Highlight processed and shipped orders */
.order-card.processed {
  border-left: 6px solid #4caf50; /* Green */
}

.order-card.shipped {
  border-left: 6px solid #2196f3; /* Blue */
}

.order-actions {
  margin-top: 12px;
  display: flex;
  gap: 16px;
}

.processed-btn,
.shipped-btn {
  padding: 8px 16px;
  font-size: 0.875rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.processed-btn {
  background-color: #4caf50;
  color: white;
}

.shipped-btn {
  background-color: #2196f3;
  color: white;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Large screens */
@media (min-width: 1024px) {
  .orders-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
