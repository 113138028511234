/* General page styling */



.home-link, .social-link {
  color: white;
}

.home-link a, .social-link a {
  color: white;
  text-decoration: none;
}



.productsDisplay {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-products {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns for large screens */
  gap: 20px; /* Spacing between grid items */
}

.product-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.product-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.image-container {
  margin-bottom: 10px;
}

.product-detail-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

/* Responsive styling */
@media (max-width: 768px) {
  .NavPan {
    
    padding: 15px;
  }

  .productsDisplay {
    flex-direction: column;
    align-items: center;
  }

  .category-products {
    grid-template-columns: 1fr; /* 1 column for screens below 768px */
  }

  .product-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .NavPan h3 {
    font-size: 1rem;
  }

  .product-card {
    padding: 10px;
  }
}

.image-container {
  position: relative;
}

.slider-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 97%;
  width: 100%;
  transform: translateY(-50%);
}

.slider-button {
  
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
}

.prev-button {
  position: absolute;
  left: 5px;
}

.next-button {
  position: absolute;
  right: 5px;
}

.load-note {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  color: #555; /* You can adjust the color as needed */
  text-align: center;
}
