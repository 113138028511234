.upload-form {
  max-width: 600px;
  margin: auto;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.upload-form h4 {
  text-align: center;
  margin-bottom: 0.5rem;
  color: #333;
}

.input-title,
.input-file {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.posts-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 1rem;
  padding: 1rem;
}

.post-card {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.user-credentials {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.avatar-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.post-images {
  width: 100%;
}

.post-image {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}

.image-slider {
  position: relative;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.slider-button:hover {
  background: rgba(0, 0, 0, 0.7);
}
