/* General Container */
.slug-products-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* First Row */
.first-row {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
}

/* Product Card */
.product-card {
  flex: 0 0 calc(25% - 20px);
  max-width: calc(25% - 20px);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Product Link */
.product-link {
  text-decoration: none;
  color: inherit;
}

/* Product Image */
.product-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Product Price */
.product-price {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Add to Cart Button */
.add-to-cart-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
}

/* Remaining Products Grid */
.remaining-products-grid {
  display: grid;
  gap: 20px;
  padding: 20px 0;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

/* Product Name */
.product-name {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

/* Thumbnail Container */
.thumbnail-container {
  display: flex;
  gap: 10px;
  justify-content: start; /* Align thumbnails to the start */
  margin-top: 10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  padding-bottom: 10px; /* Add padding to make scrollbar visible */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #007bff #f1f1f1; /* For Firefox */
}

/* Custom scrollbar for Webkit browsers (Chrome, Safari, etc.) */
.thumbnail-container::-webkit-scrollbar {
  height: 8px;
}

.thumbnail-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.thumbnail-container::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 4px;
}

.thumbnail-container::-webkit-scrollbar-thumb:hover {
  background: #0056b3;
}

/* Thumbnail Image */
.thumbnail {
  flex: 0 0 auto; /* Prevent thumbnails from shrinking */
  width: 50px;
  height: 50px;
  object-fit: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.thumbnail.active {
  border-color: #000;
}

.thumbnail:hover {
  border-color: #007bff;
}

/* Product Details */
.product-details {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

/* Rating */
.rating {
  color: #ffc107;
  margin: 15px 0;
}

/* Quantity Control */
.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.quantity-button {
  cursor: pointer;
  margin: 0 10px;
  color: #007bff;
  transition: color 0.3s ease;
}

.quantity-button:hover {
  color: #0056b3;
}

.quantity {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}


.recommendationstwo {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.recommended-productstwo {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  justify-content: center;
  scroll-snap-type: x mandatory;
  padding: 0 10px;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

.recommended-products::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Slide up slightly */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to recommended products */
.recommended-product {
  flex: 0 0 auto;
  width: 150px;
  text-align: center;
  scroll-snap-align: start;
  animation: fadeIn 0.5s ease-in-out; /* Apply fade-in animation */
  opacity: 0; /* Start with opacity 0 */
  animation-fill-mode: forwards; /* Keep the final state after animation */
}

/* Delay animation for each product */
.recommended-product:nth-child(1) { animation-delay: 0.1s; }
.recommended-product:nth-child(2) { animation-delay: 0.2s; }
.recommended-product:nth-child(3) { animation-delay: 0.3s; }
.recommended-product:nth-child(4) { animation-delay: 0.4s; }
.recommended-product:nth-child(5) { animation-delay: 0.5s; }
.recommended-product:nth-child(6) { animation-delay: 0.6s; }
.recommended-product:nth-child(7) { animation-delay: 0.7s; }
.recommended-product:nth-child(8) { animation-delay: 0.8s; }
.recommended-product:nth-child(9) { animation-delay: 0.9s; }
.recommended-product:nth-child(10) { animation-delay: 1s; }

.recommended-product img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}