.unboxing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.box {
    width: 200px;
    height: 200px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.box:hover {
    transform: scale(1.05);
    background-color: #555;
}

.box-content,
.message {
    font-size: 1.5rem;
    text-align: center;
}

@media (max-width: 768px) {
    .box {
        width: 150px;
        height: 150px;
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .box {
        width: 120px;
        height: 120px;
        font-size: 1rem;
    }
}
