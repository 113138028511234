/* General Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.product-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.form-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.product-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.submit-button {
    padding: 10px 15px;
    background-color: #00000076;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success-message {
    color: green;
    text-align: center;
}

.error-message {
    color: red;
    text-align: center;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .product-form-container {
        padding: 15px;
    }

    .form-title {
        font-size: 20px;
    }

    .submit-button {
        font-size: 14px;
    }
}

@media screen and (max-width: 480px) {
    .form-title {
        font-size: 18px;
    }

    .form-group input,
    .form-group textarea,
    .form-group select {
        font-size: 14px;
        padding: 8px;
    }

    .submit-button {
        font-size: 14px;
        padding: 8px;
    }
}

.image-preview {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}
