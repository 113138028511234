/* App.css */

.app {
  background-color: #00000076;
}
@media screen and (max-width:768px) {
  .app {
    grid-template-columns: 20% 1fr;
} 

}
.search-results
.full-screen {
  grid-template-columns: 0% 50% 50%;
}

.home__container {
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__container > * {
  margin-bottom: 10px;
}
.home__header {
  margin-bottom: 30px;
}
.username__input {
  padding: 10px;
  width: 50%;
  border-radius: 5px;
}
.email_input{
  padding: 10px;
  width: 50%;
  border-radius: 5px;
}
.mobile_input{
  padding: 10px;
  width: 50%;
  border-radius: 5px;
}
.password_input{
  padding: 10px;
  width: 50%;
  border-radius: 5px;
}
.home__cta {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #607eaa;
  color: #f9f5eb;
  outline: none;
  border: none;
  border-radius: 5px;
}


.toggle-header {
  position: sticky;
  top: 0;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  background-color: #f0f0f0;
}
.header-content {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 4px;
  
}
.logo {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.nav-links a {
  margin-left: 16px;
  text-decoration: none;
  color: #333;
}

.nav-links a:hover {
  text-decoration: underline;
}
.register-form input{
  width:80px;
}

.user-list {
  grid-column: 1;
  flex: 0 0 55px;
  padding: 16px;
  background-color: #f5eeee;
}
.user-list h2{
  margin-top: 0;
}

.user-list h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
}

.user-list ul {
  list-style-type: none;
  padding: 0;
}

.user-list li {
  margin-bottom: 8px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px;
  text-align: left;
}

.chat-window {
  grid-column: 2;
  background-image: url('./chap.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
}
@media screen and (max-width:600px) {
  .chat-window {
    width: 120%;
    left: 0;
    padding: 0;
} 
}

.chat-window h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
}

.message-container {
  flex: 1;
 
  margin-bottom: 16px;
  overflow-y: auto;
}

.message {
  
  padding: 8px;
  margin-bottom: 8px;
}

.msg-sender {
  background-color: rgb(194, 243, 194);
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  margin-left: auto;
  font-size: 15px;
}

.msg-receiver {
  background-color: #f5ccc2;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
}
.a-choice {
  background-color: #607eaa;
  text-align: center;
  padding: 10px;
}
.option{
  display: inline;
}
.puzzle-message-container {
  position: sticky;
  bottom: 0;  
}
.puzzle-message-container form input[type="text"] {
  padding: 10px;
  background-color: transparent;
  width: 140px;
  border: 1px solid #ccc;
  border-radius: 4px;
  }

.puzzle-message-container form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.puzzle-message-option input {
  width: 120px;
}
.message-input {
  display: flex;
  margin-top: 16px;
}

.message-input input {
  flex-grow: 1;
  padding: 8px;
}

.message-input button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
}

.message-input button:hover {
  background-color: #45a049;
}

.market-items {
  grid-column: 3;
  height: auto;
  background-color: #f9f9f9;
}
.market-items h2 {
  margin-top: 0;
}

.dropdown-market-items {
  display: none;
}

.result-display{
   display: flex;
   flex-direction: row;
   
}
.result-display p{
  margin: 0;
}


.search-results-two {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 80%;
  gap: 5px;
   overflow-y: auto;

}

.search-results-two img {
  width: 110px;
  height: 100px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

@media screen and (max-width:768px) {
  .search-results-two {
    display: grid;
    width: 100%;
    gap:0px;
   grid-template-columns: repeat(2, 1fr);
   overflow: auto;
    Webkit-overflow-scrolling: touch;

} 
.search-results-two img {
  width:100px;
  height:90px;
  gap: 0.5rem;
  max-height: 200px;
  object-fit: cover;
}
}

.search-results {
  flex-grow: 1; /* Take up remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%;
  margin-bottom: 0px;
}

/*.search-results {
  margin-bottom: 100px;
  padding-bottom: 130px;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
   outline: none;

}*/

.categories-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: auto; /* Enable vertical scrolling */
  scroll-snap-type: y mandatory; /* Smooth scrolling with snap */
  margin-bottom: 0px;
}

.search-results p {
  display: inline;
}

@media screen and (max-width:768px) {
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
   overflow-y: auto;
   margin-bottom: 0px;
   scroll-snap-type: y mandatory;
    Webkit-overflow-scrolling: touch;

} 
/*.search-results img {
  width:70px;
  height:60px;
  gap: 0.5rem;
  max-height: 200px;
  object-fit: cover;
}*/

}

.img-option {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.img-option img{
  width: 100%;
  height: 100px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.img-option p{
  display: block;
  margin: 0;
}

@media screen and (max-width:768px) {
  .img-option {
   grid-template-columns: repeat(2, 1fr);
} 
.img-option img {
  width:80%;
  height:80px;
  max-height: 100px;
  object-fit: cover;
  padding: 2px;
  background-color: white;
}
.img-option p {
  display: block;
  white-space: normal;
  word-wrap: break-word;
}
}

.friendssupport{
  background-color:#f0f0f0;
}
.brand-logo{
  display: flex;
}
.brand-logo h2{
  align-items: center;
  top: 0;
}
.branding-ads {
  text-align: center;
  justify-content: center;
}
.branding-ads p{
  padding: 10px;
  margin-right: 20px;
}
.branding-ads img{
  border-radius: 5px;
  padding: 2px;
}
@media screen and (max-width:768px) {
  .friendssupport {
    width: 350px;

} 
}

.cart-icon{
  font-size: 25px;
  color: gray;
  cursor: pointer;
  position: relative;
  transition: transform .4s ease;
  border: none;
  background-color: white;
  height: 25px;
}
.cart-icon:hover{
  transform: scale(1.1,1.1);
}
.cart-item-qty{
  position: absolute;
  right: -8px;
  font-size: 12px;
  color: #eee;
  background-color: #f02d34;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;

}

.result-item {
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  color: #324d67;
  flex: 1; /* Equal flex basis to allow 4 columns */
  max-width: calc(25% - 15px); /* 4 columns with gap */
}

.result-item:hover{
  transform: scale(1.1,1.1)
}
.category-content{
  border-radius: 15px;
 
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

/*.product-name{
  font-weight: 500;
}*/
.product-price{
  font-weight: 800;
  margin-top: 6px;
  color: black;
}

.desc{
position: absolute;
right: 10%;
bottom: 5%;
width: 300px;
line-height: 1.3;
display: flex;
flex-direction: column;
 color: #324d67;

}
.desc p{
  color: #5f5f5f;
  font-weight: 100;
text-align: end;
}
.desc h5{
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  /* color: black; */
  align-self: flex-end;
}
.products-heading{
  text-align: center;
  margin: 40px 0px;
 color: #324d67;

}
.products-heading h2{
  font-size: 40px;
  font-weight: 800;
}
.products-heading p{
  font-size: 16px;
  font-weight: 200;
}


 .cart-heading{
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  gap: 2px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
}

.cart-heading .heading{
  margin-left: 10px;
}
.cart-num-items{
  margin-left: 10px;
  color: #f02d34;
}
.empty-cart{
  margin:40px;
  text-align:center;
}
.empty-cart h3{
  font-weight: 600;
  font-size: 20px;
}
.cancel{
  cursor: pointer;
}
.product-container{
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
padding: 20px 10px;
z-ndex: 10;
}
.product{
  display: flex;
 gap: 30px;
  padding: 20px;
}
.product .cart-product-image{
  width:180px ;
  height: 150px;
    border-radius: 15px;
 background-color: #ebebeb;
}
.item-desc .flex{
  display: flex;
  justify-content: space-between;
  width: 350px;
   color: #324d67;

}
.item-desc .bottom{
  margin-top: 60px;
}
.flex h5{
  font-size: 24px;
}
.flex h4{
  font-size: 20px;
}
.total{
  display: flex;
  justify-content: space-between;
}
.total h3{
  font-size: 22px;

}
.remove-item{
  font-size: 24px;
  color: #f02d34;
  cursor: pointer;
  background: transparent;
  border: none;
}
.cart-bottom{
  position: absolute;
  bottom: 12px;
  right: 5px;
width: 100%;
padding: 30px 65px;

}

.btn-container{
  width: 400px;
  margin: auto;
}
.btn{
  width: 100%;
  max-width: 400px;
  padding: 10px 12px;
  border-radius: 15px;
  border: none;
  font-size: 20px;
  margin-top: 10px;
  margin-top: 40px;
  text-transform: uppercase;
  background-color: #f02d34;
  color: #fff;
  cursor: pointer;
    transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.btn:hover{
  transform: scale(1.1,1.1);
}
.product-detail-container{
  display: flex;
 gap: 40px;
 margin: 40px;
 margin-top: 60px;
 color: #324d67;

}

.product-detail-image{
  border-radius: 15px;
   background-color: #ebebeb;

  width: 100vw;
  height: auto;
  cursor: pointer;
  transition: .3s ease-in-out;
}
@media screen and (max-width:600px) {
  .product-detail-image {
    width: 100vw;
    height: auto;
    padding: 0;

} 
}


.product-detail-image:hover{
  background-color: #f02d34;

}
.small-images-container{
  display: flex;
  gap: 10px;
  margin-top: 20px;


}
.imgGape {gap:10px}
.small-image{
  border-radius: 8px;
  background-color: #ebebeb;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.selected-image{
  background-color:#f02d34;
}
.reviews{
  color: #f02d34;
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.product-detail-desc h4{
  margin-top: 10px;
}
.product-detail-desc p{
  margin-top: 10px;
}
.reviews p{
  color: #324d67;
  margin-top: 0px;
}
.product-detail-desc .price{
  font-weight: 700 ;
  font-size: 26px;
  margin-top: 30px;
  color:#f02d34;
}
.price .old-price, .product-price .old-price, .price .old-price{
  color: gray;
  text-decoration: line-through;
}
.product-detail-desc .quantity{
  display: flex;
  gap: 20px;
  margin-top: 10px ;
  align-items: center;
}
.product-detail-desc .buttons{
  display: flex;
  gap: 30px;
}
.buttons .add-to-cart{
  padding: 10px 20px;
  border: 1px solid #f02d34 ;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  color: #f02d34;
  cursor: pointer;
  width: 200px;
   transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .add-to-cart:hover{
  transform:scale(1.1,1.1)
}
.buttons .buy-now{
  width: 200px;

  padding: 10px 20px;
  background-color: #f02d34;
  color: white;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
   transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.buttons .buy-now:hover{
  transform:scale(1.1,1.1)
}
.quantity-desc{
padding: 6px;
}
.quantity-desc span{
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
}
.quantity-desc .minus{
  border-right: 1px solid gray;
  color: #f02d34;
}
.quantity-desc .num{
  border-right: 1px solid gray;
  font-size: 20px;
}
.quantity-desc .plus{
  color: rgb(49, 168, 49);

}

.maylike-products-wrapper{
  margin-top: 120px;
}
.maylike-products-wrapper h2{
  text-align: center;
  margin: 50px;
  color: #324d67;

  font-size: 28px;
}
.maylike-products-container{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}
.max-qty{
  font-weight: 500;
  color: #f02d34;
}
.success-wrapper, .cancel-wrapper{
  background-color: white;
  min-height: 60vh;

}
.success, .cancel{
  width: 1000px;
margin: auto;
margin-top: 160px;
  background-color: #dcdcdc;
  padding: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success .icon {
   color: green;
   font-size: 40px;
}
.success h2{
  text-transform: capitalize;
  margin-top: 15px 0px;
  font-weight: 900;
  font-size: 40px;
  color:#324d67;
}
.success .email-msg{
   font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.cancel p{
  font-size: 20px;
  font-weight: 600;
}
.success .description{
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 10px;
  margin-top: 30px;
}
.success .description .email{
  margin-left: 5px;
  color: #f02d34;
}
.product-max-qty{
  margin-top: 10px;
}


  
  .desc{
   bottom: 60px;
  }
  .product-detail-container{
    flex-wrap: wrap;
    }
  .product-detail-container .product-detail-image{
    width: 350px;
    height: 350px;
  }
  .cart-container{
    width: 415px;
  padding: 4px;
  }
  .cart-heading{
    margin-top: 35px;
  }
  .product-container{
    margin-top: 10px;
  }
  .product{
    padding: 20px 5px;

  }
  .product .cart-product-image{
    width: 25%;
    height: 25%;
  }
  .buttons .add-to-cart{
    width: 150px;
  }
  .buttons .buy-now{
    width: 150px;
  }
  .product-detail-container{
    margin: 20px;
  }

.item-desc .flex{
  width: 200px;
}
.top{
  flex-wrap: wrap;
  gap: 10px;


}
.item-desc .bottom{
  margin-top: 30px;
}
.flex h5{
  font-size: 16px;
   color: #324d67;
}
.flex h4{
  font-size: 16px;
   color: black;
}
.cart-bottom{
  padding: 30px;
}

.total h3{
  font-size: 20px;
}
.track {
  animation: marquee 10s linear infinite;
  width: 550%;
}
.success-wrapper, .cancel-wrapper{
 
  min-height: 69vh;
}
.success, .cancel {
   width: 370px;
  margin-top: 100px;
  padding: 20px;
}
.success{
  height: 350px;
}
.success h2{
  font-size: 17px;
}
.btn-container{
  width: 300px;
  margin: auto;
}

/* Add this to your CSS file or component styles */
.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
@media screen and (max-width:600px) {
  .modal {
    width: 100%;
    padding: 0;
} 
}

.modal.active {
  display: inline-block;
}

.modalTwo {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width:600px) {
  .modalTwo {
    width: 20%;
    padding: 0;
} 
}

.modalTwo.active {
  display: inline-block;
}


.productsDisplay{
display: flex;
gap: 250px;

}

.sideBar{
  width: 20%;
  background-color: #f9f9f9
}
.sideBar h4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  
}
.itemList{
    width:80; 
    right:0;
     display: flex; 
     flex-wrap: wrap; 
     justify-content: center; 
     align-items: center; 
     gap: 20px; 
    background-color: white;
}

.itemList p{
  margin:0;
}

.itemListTwo{
    width:80; 
    left:0;
     display: flex; 
     flex-wrap: wrap; 
      text-align: center;
     
     
     gap: 20px; 
    background-color: white;
}

.productsInfo {
  text-align: center;
}

.categoryProducts {
  display: inline-block;
  margin: 10px;
  text-align: center;
}

.imageContainer {
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: center; /* Center align items vertically */
  width: auto; /* Remove fixed width */
}

.imgDiv {
  margin: 5px;
}

.small-images-container{
  display: flex;
  gap: 10px;
  margin-top: 20px;
  border-radius: 15px;
 justify-content: center;
  transform: scale(1, 1);
  transition: transform 0.5s ease;

}
.small-image-two{
  border-radius: 8px;
  background-color: #ebebeb;
  width: 80%;
  height: 80%;
  cursor: pointer;
}
@media screen and (max-width:600px) {
  .small-image-two {
    width: 100%;
    height: 100%;
    padding: 0;
} 
}


.small-image-two:hover {
  background-color: #f02d34;
}

.selected-image{
  background-color:#f02d34;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px;
  animation: dance 1s ease-in-out infinite;
}

.red {
  background-color: #ff5733;
}

.blue {
  background-color: #33b5e5;
}

.green {
  background-color: #99cc00;
}

@keyframes dance {
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% {transform: translateY(-10px);}
}

/* Scraper.css */

.lower-div {
  text-align: center;
  margin-top: 20px;
}

.trendy-button {
  background-color: #4caf50; /* Green background color */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding */
  font-size: 16px; /* Font size */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.trendy-button:hover {
  background-color: #45a049; /* Darker green on hover */
}
.marquee-container{
  height: 25px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
}
.marquee-content{
  color: blue;
  display: inline-block;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

/* No need for repeating after a specific number of items in this case */


/* Adjust the styles as needed for your specific layout and design preferences */

/*
.user-list {
  flex: 0 0 55px;
  background-color: #f9f9f9;
  padding: 16px;
}*/
/*
.chat-window {
  flex: 1;
  background-color: #fff;
  padding: 16px;
}*/



/*style={{ display: 'flex', alignItems: 'center' }}*//* CSS for CategoryItems component */
.itemListTwo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.imgGape {
  width: 300px; /* Adjust the width as needed */
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .small-images-container {
    display: flex;
    
    overflow: hidden;

    .small-image-two {
      
      object-fit: cover;
      cursor: pointer;
      width: 250px; /* Adjust the width as needed */
      height: 220px;
      transition: transform 0.3s ease-in-out;
      
    }
    .hide {
  display: none;
      }
  }

  .result-display {
    padding: 10px;

    p {
      margin: 0;
      font-size: 14px;
      color: #333;
    }
  }

  .reviews {
    display: flex;
    width:230px;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
    background-color: #f9f9f9;

    .quantity {
      display: flex;
      align-items: center;

      .quantity-desc {
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
          margin: 0 5px;
        }
      }
    }

    .add-to-cart {
      cursor: pointer;
      background-color: #f02d34;
      color: #fff;
      border: none;
      padding: 8px 16px;
      border-radius: 5px;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: #d0262d;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .itemListTwo {
    justify-content: center;
    width: 100;
  }

  .imgGape {
    width: 100%; /* Adjust the width as needed for smaller screens */
  }
}


.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh; /* Use full viewport height */
}


.categories-grid {
    flex-grow: 1; /* This will make the grid take up the remaining space */
    overflow-y: auto; /* Allows scrolling if content overflows */
}

.footer {
    height: 20px; /* Fixed height for footer */
    text-align: center;
    
}

.recommendations {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.recommended-products {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 0 10px;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

.recommended-products::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari */
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: Slide up slightly */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to recommended products */
.recommended-product {
  flex: 0 0 auto;
  width: 150px;
  text-align: center;
  scroll-snap-align: start;
  animation: fadeIn 0.5s ease-in-out; /* Apply fade-in animation */
  opacity: 0; /* Start with opacity 0 */
  animation-fill-mode: forwards; /* Keep the final state after animation */
}

/* Delay animation for each product */
.recommended-product:nth-child(1) { animation-delay: 0.1s; }
.recommended-product:nth-child(2) { animation-delay: 0.2s; }
.recommended-product:nth-child(3) { animation-delay: 0.3s; }
.recommended-product:nth-child(4) { animation-delay: 0.4s; }
.recommended-product:nth-child(5) { animation-delay: 0.5s; }
.recommended-product:nth-child(6) { animation-delay: 0.6s; }
.recommended-product:nth-child(7) { animation-delay: 0.7s; }
.recommended-product:nth-child(8) { animation-delay: 0.8s; }
.recommended-product:nth-child(9) { animation-delay: 0.9s; }
.recommended-product:nth-child(10) { animation-delay: 1s; }

.recommended-product img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}